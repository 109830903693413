/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <participation-placement-annulation-editor-dialog
      v-if="activeParticipationPlacement"
      ref="pPlacementAnnulationDialog"
      :item="activeParticipationPlacement"
      @save="saveParticipationPlacementAnnulation"
    />
    <div class="statistic-box">
      <participation-placement-statistic
        :extraQueryArg="placement != null ? `offreId=${placement.offreId}` : ``"
        ref="pPlacementStatistic"
        class="mt-3 mb-2"
      />
    </div>
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par nom et/ou prénom'"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6 col-sm-12 d-flex">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="sexeDe"
                    :options="sexeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Sexe DE'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurVulnerabilite"
                    :options="demandeurVulnerabiliteOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Vulnérabilité'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurType"
                    :options="demandeurTypeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Type de DE'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="typeEntreprise"
                    :options="typeEntrepriseOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Type Entreprise'"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="border p-3 rounded shadow-0 mt-2" v-if="showAdvancedFilter">
            <div class="row p-fluid grid">
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="dateDeb">Date de début</label>
                <p-calendar
                  placeholder="Date de début"
                  v-model="dateDeb"
                  locale="fr"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="dateFin">Date de fin</label>
                <p-calendar
                  placeholder="Date de fin"
                  class="flex-grow-1"
                  v-model="dateFin"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="statutPlacement">Statut de Mise En Relation</label>
                <p-dropdown
                  class="flex-grow-1"
                  v-model="statutPlacement"
                  :options="statutPlacementOptions"
                  optionLabel="label"
                  optionValue="value"
                  :placeholder="'Statut de Mise En Relation'"
                  display="chip"
                />
              </div>
              <template v-for="(option, index) in filterOptions">
                <div
                  class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                  v-if="option.visible"
                  :key="index"
                >
                  <label :for="option.labelField">{{ option.label }}</label>

                  <AsyncMultiSelect
                    v-model="option.value"
                    :multiple="true"
                    :queryUrl="option.queryUrl"
                    :optionLabel="option.labelField"
                    :placeholder="option.label"
                    :emitObject="false"
                    :displayMode="option.displayMode"
                    :searchOptions="[option.labelField]"
                  />
                </div>
              </template>
            </div>
            <div class="d-flex justify-content-end">
              <!-- <PButton class="p-button-primary p-button- py-1 p-button-sm ms-1" @click.prevent.stop="resetFilterWithFromOffre">
            Matching avec les critères de l'offre
          </PButton> -->
              <PButton
                class="p-button-danger p-button- py-1 p-button-sm ms-1"
                @click.prevent.stop="reinitialiserFiltres"
              >
                Réinitialiser les filtres
              </PButton>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des placements bénéficiés</h4>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group">
          <!-- <a @click.prevent="imprimerAttestation" href="" class="style_btn btn btn-warning">Imprimer attestation</a> -->
        </div>
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-white p-3 border">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              selectable
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <!-- 
                <template #cell(id)="data">
                  PP000{{ data.item.id }}
                </template> -->

              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>
              <template #cell(dateDebut)="{ item: pp }">
                <span class="text-uppercase">{{
                  pp.dateDebut != null ? $dayjs(pp.dateDebut).format("DD-MM-YYYY") : "-"
                }}</span>
              </template>
              <template #cell(dateFin)="{ item: pp }">
                <span class="text-uppercase">{{
                  pp.dateFin != null ? $dayjs(pp.dateFin).format("DD-MM-YYYY") : "-"
                }}</span>
              </template>
              <template #cell(datePriseService)="{ item: pp }">
                <span class="text-uppercase">{{
                  datePriseService != null
                    ? $dayjs(pp.datePriseService).format("DD-MM-YYYY")
                    : "-"
                }}</span>
              </template>
              <template #cell(lastDateMoisPresencePoste)="{ item: pp }">
                <span class="text-uppercase">{{
                  pp.lastDateMoisPresencePoste != null
                    ? $dayjs(pp.lastDateMoisPresencePoste).format("MMM YYYY")
                    : "-"
                }}</span>
              </template>
              <template #cell(statutPlacement)="{ item: participation }">
                <PTag
                  class="text-uppercase badge badge-primary"
                  :severity="getStatusParticipationColor(participation.statutPlacement)"
                  v-if="participation.statutPlacement"
                  >{{ participation.statutPlacement }}
                </PTag>
              </template>

              <template #row-details="row">
                <PTabView ref="tabview1" :activeIndex.sync="activeTab">
                  <PTabPanel header="Fiche d'enquête poste stage">
                    <QuestionnairePosteStageDetail
                      v-if="activeTab == 0"
                      :demandeurId="row.item.beneficiaireId"
                      :participationPlacementId="row.item.id"
                    />
                  </PTabPanel>
                </PTabView>
              </template>

              <template #cell(actions)="row">
                <div class="d-flex justify-content-end">
                  <button
                    v-tooltip.top="'Voir les détails'"
                    class="btn btn-sm py-1 btn-outline-primary me-1"
                    @click="mxToggleDetails(row, tableData)"
                  >
                    <i class="bi bi-journal-check"></i>
                  </button>
                  <a
                    v-if="row.item.statutPlacement == 'RETENU'"
                    class="btn btn-warning rounded btn-sm me-1"
                    @click.prevent="telechargerAttestationStage(row.item)"
                    v-tooltip.top="'Télécharger Attestation de stage'"
                    ><i class="bi bi-file-medical"></i
                  ></a>
                  <!-- <a
                    v-if="row.item.contrat_id == null"
                    class="btn btn-secondary rounded btn-sm me-1"
                    @click.prevent="generateContract(row.item)"
                    title
                    ><i class="bi bi-plus"></i
                  ></a>
                  <a class="btn btn-success rounded-0 btn-sm me-1" title
                    ><i class="bi bi-pencil-square"></i
                  ></a> -->
                  <!-- <a
                    class="btn btn-primary rounded-0 btn-sm me-1"
                    v-tooltip.top="'Prise de service'"
                    @click.stop="showPriseDeServiceEditorDialog(row.item)"
                    ><i class="bi bi-chevron-bar-expand"></i
                  ></a>
                   -->
                  <a
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    v-tooltip.top="'Annuler/Invalider ce placement'"
                    href="#"
                    title
                    @click.stop="showAnnulationPlacementEditorDialog(row.item)"
                    ><i class="bi bi-journal-x"></i
                  ></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ mxPagination.page * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div
            class="col-sm-4 col-lg-3 mb-3"
            v-for="i in mxPagination.rowsPerPage"
            :key="i"
          >
            <div class="style_profil_card p-3 pt-3">
              <div class="custom-skeleton p-3">
                <div
                  class="d-flex flex-column mb-2 align-items-center justify-content-center"
                >
                  <PSkeleton width="10rem" class="mb-2"></PSkeleton>
                  <PSkeleton shape="circle" size="6rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import PresencePosteEditorDialog from "../../../components/espace/entreprise/editor/PresencePosteEditorDialog.vue";
import CessationServiceEditorDialog from "../../../components/espace/entreprise/editor/CessationServiceEditorDialog.vue";
import PriseDeServiceEditorDialog from "../../../components/espace/entreprise/editor/PriseDeServiceEditorDialog.vue";
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";
import ParticipationPlacementStatistic from "../../../components/statistic/placement/ParticipationPlacementStatistic.vue";
import QuestionnairePosteStageDetail from "../conseiller/QuestionnairePosteStageDetail.vue";
import ParticipationPlacementAnnulationEditorDialog from "../../../components/espace/common/placement/editor/ParticipationPlacementAnnulationEditorDialog.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    placement: {
      type: Object,
      default: null,
    },
  },
  components: {
    PresencePosteEditorDialog,
    CessationServiceEditorDialog,
    PriseDeServiceEditorDialog,
    // ActiviteStatistic,
    ParticipationPlacementStatistic,
    AsyncMultiSelect,
    CAutoComplete,
    QuestionnairePosteStageDetail,
    ParticipationPlacementAnnulationEditorDialog,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeParticipationPlacement: null,
      activeTab: 0,
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      demandeurType: null,
      statutEvaluationOptions: [
        { value: null, label: "Tous" },
        { value: "EVALUE", label: "EVALUE" },
        { value: "NON_EVALUE", label: "NON EVALUE" },
      ],
      statutEvaluation: null,
      typeCandidatureOptions: [
        { value: null, label: "Tous" },
        { value: "RECOMMANDE", label: "RECOMMANDE" },
        { value: "SPONTANNEE", label: "SPONTANNEE" },
      ],
      statutPlacementOptions: [
        { value: null, label: "Tous" },
        { value: "PROPOSE", label: "PROPOSE" },
        { value: "RETENU", label: "RETENU" },
        { value: "EXIGE", label: "EXIGE" },
      ],
      statutPlacement: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "VULNERABLE" },
        { value: false, label: "NON VULNERABLE" },
      ],
      demandeurVulnerabilite: null,
      typeEntrepriseOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "AZOLI" },
        { value: false, label: "NON AZOLI" },
      ],
      typeEntreprise: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des placements bénéficiés",
                subtitle: "Liste des placements bénéficiés",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "participationPlacements/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des placements bénéficiés.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des placements bénéficiés",
                subtitle: "Liste des placements bénéficiés",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "participationPlacements/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des placements bénéficiés.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["candidature.candidat.nomPrenom"],
      filterOptions: [
        {
          column: "placement.entreprise.id",
          value: [],
          label: "Dénomination Entreprise",
          dropdown: true,
          labelField: "raisonSocial",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "entreprises",
          multiple: true,
          visible: true,
        },
        {
          column: "placement.entreprise.id",
          value: [],
          label: "Ifu Entreprise",
          dropdown: true,
          multiple: true,
          labelField: "ifu",
          displayMode: "classic",
          valueKey: "ifu",
          queryUrl: "entreprises",
          visible: true,
        },
        {
          column: "typePlacement.id",
          value: [],
          label: "Type emploi",
          dropdown: true,
          labelField: "libelle",
          displayMode: "mega-menu",
          valueKey: "id",
          queryUrl: "typePlacements",
          visible: true,
        },
        // {
        //   column: "candidature.candidat.langue",
        //   value: [],
        //   label: "Langues",
        //   dropdown: true,
        //   labelField: "nom",
        //   displayMode: "mega-menu",
        //   valueKey: "id",
        //   queryUrl: "ethnies",
        //   visible: true,
        // },
        // {
        //   column: "candidature.candidat.diplome",
        //   value: "",
        //   label: "Diplômes",
        //   valueKey: "id",
        //   dropdown: true,
        //   dropdown: true,
        //   displayMode: "mega-menu",
        //   labelField: "code",
        //   queryUrl: "niveaux",
        //   visible: true,
        // },
        // {
        //   column: "candidature.candidat.niveauInstruction",
        //   value: "",
        //   label: "Niveau instruction",
        //   dropdown: true,
        //   valueKey: "id",
        //   multiple: true,
        //   labelField: "code",
        //   displayMode: "mega-menu",
        //   queryUrl: "instructions",
        //   visible: true,
        // },
        // {
        //   column: "candidature.candidat.nature",
        //   value: "",
        //   label: "Nature contrat",
        //   labelField: "code",
        //   displayMode: "mega-menu",
        //   queryUrl: "natures",
        //   valueKey: "id",
        //   dropdown: true,
        //   visible: true,
        // },
        {
          column: "candidature.candidat.departement",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "classic",
          multiple: true,
          valueKey: "id",
          dropdown: true,
          visible: true,
        },
        {
          column: "candidature.candidat.commune",
          value: "",
          labelField: "nom",
          multiple: true,
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
        },
        {
          column: "candidature.candidat.arrondissement",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          multiple: true,
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
        },
        {
          column: "candidature.candidat.ville.id",
          value: "",
          labelField: "nom",
          multiple: true,
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "beneficiaireNom", label: "Bénéf Nom" },
        { key: "beneficiairePrenom", label: "Bénéf Prénom" },
        { key: "posteOccupe", label: "Poste" },
        { key: "entrepriseNom", label: "Entreprise" },
        { key: "typePlacement.libelle", label: "Type.Emploi" },
        { key: "dateDebut", label: "Début.Cont" },
        { key: "dateFin", label: "Fin.Cont" },
        { key: "datePriseService", label: "Date.PS" },
        { key: "statutPlacement", label: "Statut" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchContratStage: "participationPlacement/fetchContratStage",
      createOrUpdatePriseService: "priseService/createOrUpdatePriseService",
      createOrUpdateArretContrat: "arretContrat/createOrUpdateArretContrat",
      createOrUpdatePresencePoste: "presencePoste/createOrUpdatePresencePoste",
      telechargerAttestationStage: "participationPlacement/telechargerAttestationStage",
      annulerParticipationPlacement:
        "participationPlacement/annulerParticipationPlacement",
    }),
    showAnnulationPlacementEditorDialog(participationPlacement) {
      this.activeParticipationPlacement = {
        ...participationPlacement,
      };
      this.$refs.pPlacementAnnulationDialog.show();
    },
    async saveParticipationPlacementAnnulation(participationPlacement) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment annuler le placement de ${participationPlacement.beneficiaireNom} ${participationPlacement.beneficiairePrenom}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.annulerParticipationPlacement(participationPlacement)
            .then((data) => {
              this.$toast.success("Placement ANNULE avec succès!", {
                position: "top-right",
                duration: 8000,
              });
              this.queryStatistics();
            })
            .catch((err) => err);
        },
        reject: () => {},
      });
    },
    async generateContract(pp) {
      await this.fetchContratStage({ participationPlacementId: pp.id }).then((data) => {
        this.contratStage({
          pp,
          ...data,
        });
      });
    },
    getStatusColor(activite) {
      let bg = "event-box-pending text-white";

      if (activite.statusActivite == "ACTIF") {
        bg = "event-box-done text-white";
      } else if (activite.statusActivite == "ANNULE") {
        bg = "event-box-missed text-white";
      } else if (activite.statusActivite == "SUSPENDU") {
        bg = "event-box-reported text-white";
      }
      return bg;
    },
    getStatusParticipationColor(status) {
      let color = "";
      if (status == "CANDIDATE") {
        color = "primary";
      } else if (status == "RETENU") {
        color = "success";
      } else if (status == "PRESELECTIONNE") {
        color = "warning";
      } else if (status == "RECALE" || status == "ANNULE") {
        color = "danger";
      } else if (status == "PROPOSE") {
        color = "info";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },
    reinitialiserFiltres() {
      this.filterOptions.forEach((option) => (option.value = []));
      this.demandeurType = null;
      this.typeEntreprise = null;
      this.typeCandidature = null;
      this.statutPlacement = null;
      this.statutEvaluation = null;
      this.sexeDe = null;
      this.demandeurVulnerabilite = null;
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "participationPlacement",
        search: this.search,
        fetcherMethod: "participationPlacement/fetchParticipationPlacements",
        dataGetter: "participationPlacement/participationPlacements",
        paginationGetter: "participationPlacement/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam(
            "candidature.candidat.categorieDemandeur",
            this.demandeurType
          );
          paramPlus = this.mxAddQueryParam(
            "candidature.candidat.estVulnerable",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "candidature.candidat.sexe",
            this.sexeDe,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "candidature.typeCandidature",
            this.typeCandidature,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "statutPlacement",
            this.statutPlacement,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("", this.typeEntreprise, paramPlus);

          let params = `createdDate=${dateDeb}&createdDate=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style></style>
